import React, { useState } from "react";
import { Box, Button, Typography, Checkbox, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate, useLocation } from "react-router-dom";
import MenuSection from "../components/MenuSection";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const CanjesPage = () => {
  const theme = useTheme();
  const isWideScreen = useMediaQuery("(min-width:850px)");
  const navigate = useNavigate();
  const location = useLocation();

  const [selected, setSelected] = useState(null);

  const options = [
    { canje: "PROMO1 MOTORSPORT 100", unidades: 100, descuento: "$ 0,00" },
    { canje: "PROMO1 MOTORSPORT 4000", unidades: 4000, descuento: "$ 12.900,00" },
    { canje: "PROMO1 MOTORSPORT 12000", unidades: 12000, descuento: "$ 32.900,00" },
  ];

  const handleSelect = index => {
    setSelected(index);
  };

  const handleCanjes = () => {
    alert("Canjeado");
  };

  return (
    <>
      <MenuSection />

      <Box
        sx={{
          position: "absolute",
          width: isWideScreen ? "60%" : "80%",
          maxWidth: isWideScreen ? "600px" : "350px",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          p: 3,
          borderRadius: "8px",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            mb: isWideScreen ? 1 : 3,
            fontSize: "1.1rem",
            textTransform: "uppercase",
          }}
        >
          Opciones de Fidelización
        </Typography>

        {isWideScreen ? (
          <Box component="table" width="100%" sx={{ borderCollapse: "collapse" }}>
            <Box component="thead">
              <Box component="tr">
                <Box component="th" sx={{ textAlign: "left", p: 1 }}>
                  Canje
                </Box>
                <Box component="th" sx={{ textAlign: "right", p: 1 }}>
                  U. Necesarias
                </Box>
                <Box component="th" sx={{ textAlign: "right", p: 1 }}>
                  Descuento
                </Box>
                <Box component="th" sx={{ textAlign: "center", p: 1 }}>
                  Elegir
                </Box>
              </Box>
            </Box>
            <Box component="tbody">
              {options.map((option, index) => (
                <Box
                  component="tr"
                  key={option.canje}
                  sx={{
                    backgroundColor: selected === index ? "rgba(29, 33, 29, 0.1)" : "transparent",
                    borderRadius: "4px",
                    borderBottom: "1px solid #e0e0e0",
                  }}
                >
                  <Box component="td" sx={{ p: 1, fontSize: "0.875rem" }}>
                    {option.canje}
                  </Box>
                  <Box component="td" sx={{ p: 1, textAlign: "right", fontSize: "0.875rem" }}>
                    {option.unidades}
                  </Box>
                  <Box component="td" sx={{ p: 1, textAlign: "right", fontSize: "0.875rem" }}>
                    {option.descuento}
                  </Box>
                  <Box component="td" sx={{ p: 1, textAlign: "center" }}>
                    <Checkbox
                      checked={selected === index}
                      onChange={() => handleSelect(index)}
                      icon={<CheckBoxOutlineBlankIcon sx={{ color: theme.bandera.colorSecundario }} />}
                      checkedIcon={<CheckBoxIcon sx={{ color: theme.bandera.colorSecundario }} />}
                    />
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        ) : (
          options.map((option, index) => (
            <Box
              key={option.canje}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                p: 2,
                mb: 2,
                backgroundColor: selected === index ? "rgba(29, 33, 29, 0.1)" : "transparent",
                borderRadius: "4px",
                border: selected === index ? `1px solid ${theme.bandera.colorSecundario}` : "1px solid #e0e0e0",
              }}
            >
              <Box>
                <Typography variant="body1" sx={{ fontSize: "0.875rem" }}>
                  {option.canje}
                </Typography>
                <Typography variant="body2" sx={{ fontSize: "0.8rem", color: "text.secondary" }}>
                  U. Necesarias: {option.unidades}
                </Typography>
                <Typography variant="body2" sx={{ fontSize: "0.8rem", color: "text.secondary" }}>
                  Descuento: {option.descuento}
                </Typography>
              </Box>
              <Checkbox
                checked={selected === index}
                onChange={() => handleSelect(index)}
                icon={<CheckBoxOutlineBlankIcon sx={{ color: theme.bandera.colorSecundario }} />}
                checkedIcon={<CheckBoxIcon sx={{ color: theme.bandera.colorSecundario }} />}
              />
            </Box>
          ))
        )}

        <Button
          variant="contained"
          onClick={handleCanjes}
          sx={{
            mt: 2,
            width: "100%",
            backgroundColor: theme.bandera.colorSecundario,
            ":hover": { backgroundColor: theme.bandera.colorSecundario },
            fontSize: "0.875rem",
            padding: "8px 0",
          }}
        >
          Aceptar
        </Button>
      </Box>
    </>
  );
};

export default CanjesPage;
