import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Switch,
  Typography,
  Modal,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogContent,
  CircularProgress,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MenuSection from "../components/MenuSection";
import puestos from "../api/puestos";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import TagIcon from "@mui/icons-material/Tag";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import qrs from "../api/qr";
import { useNavigate } from "react-router-dom";
import { guardarTalonarios } from "../helpers/guardarTalonariosHelper";

const ConfiguracionesPage = () => {
  const [modalPuestoOpen, setModalPuestoOpen] = useState(false);

  // const [isQRSwitchOn, setIsQRSwitchOn] = useState(() => JSON.parse(localStorage.getItem("isQRSwitchOn")) ?? true);
  const [isQRSwitchOn, setIsQRSwitchOn] = useState(() => {
    const storedSwitchState = JSON.parse(localStorage.getItem("isQRSwitchOn"));
    return storedSwitchState !== null ? storedSwitchState : false; // Default a "false" para LISTA QRS
  });

  const [modalPrefijoOpen, setModalPrefijoOpen] = useState(false);
  const [prefijo, setPrefijo] = useState("");
  const [qrPosition, setQrPosition] = useState("");
  const [selectedPuestoId, setSelectedPuestoId] = useState("");
  const [getPuestos, setGetPuestos] = useState([]);
  const [qrArray, setQRArray] = useState([]);
  const [openResultDialog, setOpenResultDialog] = useState(false);
  const [validationResult, setValidationResult] = useState({ success: false, message: "" });
  const [openSaveDialog, setOpenSaveDialog] = useState(false);
  const [loading, setLoading] = useState(false); // Estado para el indicador de carga
  const theme = useTheme();
  const navigate = useNavigate();
  const nic = localStorage.getItem("formattedNic");
  // Nuevo estado para el switch de lista de precios
  const [utilizaListaPrecios, setUtilizaListaPrecios] = useState(() => {
    const storedListaPrecioState = JSON.parse(localStorage.getItem("utilizaListaPrecios"));
    return storedListaPrecioState !== null ? storedListaPrecioState : false;
  });

  // Manejar el cambio de estado del nuevo switch de lista de precios
  const handleListaPrecioSwitchChange = () => {
    const newSwitchState = !utilizaListaPrecios;
    setUtilizaListaPrecios(newSwitchState);
    localStorage.setItem("utilizaListaPrecios", JSON.stringify(newSwitchState));
  };

  const handleSelectChangePuesto = event => {
    const { value } = event.target;
    console.log(value);

    setSelectedPuestoId(value);
    localStorage.setItem("puesto", JSON.stringify(getPuestos[value]));
    const puesto = JSON.parse(localStorage.getItem("puesto"));
    console.log(puesto);

    const cantidadPlayerosSolicitados = puesto.caja.cantidadPlayerosSolicitados;
    localStorage.setItem("cantidadPlayerosSolicitados", cantidadPlayerosSolicitados);
    localStorage.setItem("solicitarAforadores", puesto.caja.solicitarAforadores);
    localStorage.setItem("idCaja", puesto.caja.idCaja);
    localStorage.setItem("perteneceFULL", puesto.talonarios[0].perteneceFULL);
    localStorage.setItem("combustiblesSoloPorDespachos", puesto.combustiblesSoloPorDespachos);
    console.log("Cantidad de Playeros Solicitados:", cantidadPlayerosSolicitados);
    guardarTalonarios(puesto);
  };

  const handleOpenModalPuesto = async () => {
    setModalPuestoOpen(true);
    setLoading(true);
    try {
      let allPuestos = localStorage.getItem("puestosArray");
      if (allPuestos) {
        allPuestos = JSON.parse(allPuestos);
        setGetPuestos(allPuestos);
        console.log(allPuestos);
      } else {
        allPuestos = await puestos.getPuestos();
        setGetPuestos(allPuestos);
        localStorage.setItem("puestosArray", JSON.stringify(allPuestos));
        console.log(JSON.stringify(allPuestos));
      }
    } catch (error) {
      console.log("Ocurrió un error al obtener los puestos:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleContinuarClick = () => {
    setModalPuestoOpen(false);
  };

  const handlePrefijoChange = event => {
    const inputValue = event.target.value;
    if (/^\d*$/.test(inputValue)) {
      setPrefijo(inputValue);
    }
  };

  const handlePrefijoClick = () => {
    localStorage.setItem("prefijoFront", prefijo);
    setModalPrefijoOpen(false);
    console.log(localStorage.getItem("prefijoFront"));
  };

  const handleSwitchChange = () => {
    const newSwitchState = !isQRSwitchOn;
    setIsQRSwitchOn(newSwitchState);
    localStorage.setItem("isQRSwitchOn", JSON.stringify(newSwitchState));
    if (newSwitchState) {
      // Si el switch está encendido, es QR INDIVIDUAL
      console.log("sin listado");
      console.log(qrArray);
      localStorage.setItem("conListadoQR", false);
    } else {
      // Si el switch está apagado, es LISTA QRS
      console.log("con listado");
      localStorage.setItem("conListadoQR", true);
    }
  };

  const handleQrPositionChange = event => {
    setQrPosition(event.target.value);
  };

  const handleValidateClick = () => {
    console.log("QR Position:", qrPosition);
    console.log(qrArray);
    const posicionQR = qrArray.find(qr => qr.posi === +qrPosition);

    if (posicionQR) {
      console.log("el qr fue validado con exito");
      localStorage.setItem("idPosicionQR", posicionQR.posi);
      localStorage.setItem("urlPosicionQR", posicionQR.url);
      setValidationResult({
        success: true,
        message: "El QR fue validado con éxito.",
      });
    } else {
      console.log("el qr no fue validado");
      localStorage.removeItem("idPosicionQR");
      localStorage.removeItem("urlPosicionQR");
      setValidationResult({
        success: false,
        message: "El QR no pudo ser validado. Por favor, verifique la posición ingresada.",
      });
    }

    setOpenResultDialog(true);
    setTimeout(() => setOpenResultDialog(false), 2000);
  };

  useEffect(() => {
    const storedSwitchState = JSON.parse(localStorage.getItem("isQRSwitchOn"));
    if (storedSwitchState !== null) {
      setIsQRSwitchOn(storedSwitchState);
    }

    const storedListaPrecioState = JSON.parse(localStorage.getItem("utilizaListaPrecios"));
    if (storedListaPrecioState !== null) {
      setUtilizaListaPrecios(storedListaPrecioState);
    }

    fetchQRS();
  }, []);

  const fetchQRS = async () => {
    setLoading(true);
    try {
      const qrsEstacion = await qrs.getQRs();
      const qrsOrdenados = qrsEstacion.sort((a, b) => a.posi - b.posi);
      setQRArray(qrsOrdenados);
      localStorage.setItem("qrsArray", JSON.stringify(qrsOrdenados));
    } catch (error) {
      console.error("Error al obtener los datos:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleGuardar = () => {
    setOpenSaveDialog(true);
    setTimeout(() => {
      setOpenSaveDialog(false);
      navigate("/home"); // Navegar a "/home" después de 2 segundos
    }, 2000);
  };
  return (
    <>
      <MenuSection />

      {/* Menú con borde */}
      <Box
        sx={{
          mb: 6,
          marginBottom: window.innerWidth < 700 ? 8 : 4,

          p: 2,
          bgcolor: theme.palette.background.paper,
          border: loading ? "none" : "0.5px solid", // Condiciona el borde según el estado de loading
          borderColor: loading ? "transparent" : theme.bandera.colorPrimario, // Asegura que el borde esté transparente cuando loading
          borderRadius: 1,
        }}
      >
        {/* Indicador de carga */}
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "100px" }}>
            <CircularProgress sx={{ color: theme.bandera.colorSecundario }} />
          </Box>
        ) : (
          <>
            {/* Opciones en columna */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* Opción Seleccionar Puesto */}
              <Button
                variant="contained"
                onClick={handleOpenModalPuesto}
                sx={{
                  backgroundColor: theme.bandera.colorSecundario,
                  width: "100%",
                  justifyContent: "flex-start",
                  padding: "12px 24px",
                  fontSize: "1.2rem",
                  minHeight: "56px",
                  ":hover": {
                    backgroundColor: theme.bandera.colorSecundario,
                  },
                }}
                startIcon={<AddCircleIcon sx={{ fontSize: "2rem" }} />}
              >
                Seleccionar Puesto
              </Button>

              {/* Opción Ingresar Prefijo */}
              <Button
                variant="contained"
                onClick={() => setModalPrefijoOpen(true)}
                sx={{
                  marginTop: 2,
                  backgroundColor: theme.bandera.colorSecundario,
                  width: "100%",
                  justifyContent: "flex-start",
                  padding: "12px 24px",
                  fontSize: "1.2rem",
                  minHeight: "56px",
                  ":hover": {
                    backgroundColor: theme.bandera.colorSecundario,
                  },
                }}
                startIcon={<TagIcon sx={{ fontSize: "2rem" }} />}
              >
                Ingresar Prefijo
              </Button>
            </Box>

            {/* Opción con Switch */}
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", mb: 2, mt: 3 }}>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: utilizaListaPrecios ? "normal" : "bold",
                  color: utilizaListaPrecios ? "gray" : theme.bandera.colorPrimario,
                }}
              >
                Sin Lista de Precios
              </Typography>
              <Switch checked={utilizaListaPrecios} onChange={handleListaPrecioSwitchChange} color="primary" />
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: utilizaListaPrecios ? "bold" : "normal",
                  color: utilizaListaPrecios ? theme.bandera.colorPrimario : "gray",
                }}
              >
                Con Lista de Precios
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                mt: 3,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mb: 2,
                }}
              >
                <Typography sx={{ fontSize: "18px", fontWeight: isQRSwitchOn ? "normal" : "bold", color: isQRSwitchOn ? "gray" : theme.bandera.colorPrimario }}>
                  LISTA QRS
                </Typography>
                <Switch checked={isQRSwitchOn} onChange={handleSwitchChange} color="primary" />
                <Typography sx={{ fontSize: "18px", fontWeight: isQRSwitchOn ? "bold" : "normal", color: isQRSwitchOn ? theme.bandera.colorPrimario : "gray" }}>
                  QR INDIVIDUAL
                </Typography>
              </Box>

              {isQRSwitchOn && (
                <>
                  <TextField
                    variant="outlined"
                    placeholder="INGRESE POSICIÓN DE QR"
                    value={qrPosition}
                    onChange={handleQrPositionChange}
                    sx={{ mb: 1, width: "90%" }}
                    type="number"
                    InputProps={{
                      sx: {
                        fontSize: "17px",
                      },
                      inputProps: {
                        style: { textAlign: "center" },
                      },
                    }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleValidateClick}
                    sx={{ fontSize: "16px", fontWeight: "bold", backgroundColor: theme.bandera.colorSecundario }}
                  >
                    VALIDAR
                  </Button>
                </>
              )}
            </Box>
          </>
        )}
      </Box>

      {/* Modal para agregar prefijo */}
      <Modal open={modalPrefijoOpen} onClose={() => setModalPrefijoOpen(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            width: "250px",
            p: 4,
            borderRadius: 2,
            display: "grid",
            gridTemplateRows: "auto 1fr",
          }}
        >
          <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
            Ingrese un prefijo
          </Typography>
          <TextField type="number" value={prefijo} onChange={handlePrefijoChange} placeholder="Solo números" sx={{ height: "1fr" }} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: 2,
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: theme.bandera.colorSecundario,
                ":hover": {
                  backgroundColor: theme.bandera.colorSecundario,
                },
              }}
              onClick={handlePrefijoClick}
            >
              Continuar
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Modal para seleccionar puesto */}
      <Modal open={modalPuestoOpen} onClose={() => setModalPuestoOpen(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            width: "250px",
            p: 4,
            borderRadius: 2,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
            Puesto de emisión
          </Typography>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="puesto-select-label">Puestos</InputLabel>
            <Select labelId="puesto-select-label" id="puesto-select" value={selectedPuestoId} label="Puestos" onChange={handleSelectChangePuesto}>
              {getPuestos.map((puesto, index) => (
                <MenuItem key={puesto.nombre} value={index}>
                  {puesto.nombre}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: 2,
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: theme.bandera.colorSecundario,
                ":hover": {
                  backgroundColor: theme.bandera.colorSecundario,
                },
              }}
              onClick={handleContinuarClick}
            >
              Continuar
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Diálogo de resultado */}
      <Dialog open={openResultDialog} onClose={() => setOpenResultDialog(false)}>
        <DialogContent>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {validationResult.success ? (
              <CheckCircleIcon color="success" style={{ fontSize: 50, marginRight: "10px" }} />
            ) : (
              <ErrorIcon color="error" style={{ fontSize: 50, marginRight: "10px" }} />
            )}
            <Typography fontWeight="bold">{validationResult.message}</Typography>
          </Box>
        </DialogContent>
      </Dialog>

      {/* Diálogo de guardado */}
      <Dialog open={openSaveDialog} onClose={() => setOpenSaveDialog(false)}>
        <DialogContent>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <CheckCircleIcon color="success" style={{ fontSize: 50, marginRight: "10px" }} />
            <Typography fontWeight="bold">Configuraciones guardadas con éxito</Typography>
          </Box>
        </DialogContent>
      </Dialog>

      {/* Botón Guardar */}
      <Button
        variant="contained"
        sx={{
          position: "fixed",
          bottom: 45,
          fontWeight: "bold",
          right: 20,
          backgroundColor: theme.bandera.colorSecundario,
          fontSize: "1.2rem",
          padding: "10px 22px",
          ":hover": {
            backgroundColor: theme.bandera.colorSecundario,
          },
        }}
        onClick={handleGuardar}
      >
        GUARDAR
      </Button>
    </>
  );
};

export default ConfiguracionesPage;
