import { useDispatch, useSelector } from "react-redux";
import { agregarProducto, borrarProductos, actualizarProducto, actualizarIdProductoArray, reiniciarProductos } from "../store/product/productSlice";

export const useProductStore = () => {
  const { productos, idProductoArray } = useSelector(state => state.product);

  const dispatch = useDispatch();

  const addProducto = producto => {
    dispatch(agregarProducto(producto));
  };

  const clearProductos = () => {
    dispatch(borrarProductos());
  };

  const updateProducto = productos => {
    dispatch(actualizarProducto(productos));
  };

  const setIdProductoArray = id => {
    dispatch(actualizarIdProductoArray(id));
  };

  const resetProductos = () => {
    dispatch(reiniciarProductos());
  };

  return {
    productos,
    idProductoArray,
    addProducto,
    clearProductos,
    updateProducto,
    setIdProductoArray,
    resetProductos,
  };
};
